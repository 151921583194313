<template>
  <div>
    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-sm-9 pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-auto" v-if="1 === 0">
        <div class="row mb-3 no-gutters justify-content-between align-items-center">
          <div class="col-md-auto">
            <label class="col-form-label pr-2 font-weight-bold">Filter By: </label>
          </div>
          <div class="col-md-auto px-1">
            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs"
                    v-model="filters.fleet_id">
              <option :value="null">-- ALL FLEET --</option>
              <option :value="fleet.id" v-for="fleet in fleetKeys">{{ fleet.name ? fleet.name.toUpperCase() : '' }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-auto pb-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
          </div>
          <select class="form-control form-control-sm rounded-left" v-model="filters.count" id="rowCount">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="10000">All</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row no-gutters mb-2">
      <div class="col-12 text-right">
        <button class="e-btn e-btn-blue-gray force-white-all" @click="generateFile">
          <font-awesome-icon icon="file" /> GENERATE PDF
        </button>
      </div>
    </div>
    <div class="row no-gutters" style="height: 75vh; overflow-y: auto">
      <div class="col-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle text-center" style="width: 1em" scope="col">#</th>
            <th class="align-middle text-center" scope="col">VESSEL</th>
            <th class="align-middle text-center" scope="col">MANAGER</th>
            <th class="align-middle text-center" style="min-width: 25em" scope="col">REPORT TITLE</th>
            <th class="align-middle text-center" style="width: 5em" scope="col">STATUS</th>
            <th class="align-middle text-center" style="width: 10em" scope="col">PORT</th>
            <th class="align-middle text-center" style="width: 7em" scope="col">COUNTRY</th>
            <th class="align-middle text-center" style="min-width: 9em" scope="col">INSPECTION DATE</th>
<!--            <th class="align-middle text-center" style="width: 1em; font-size: 11px;" scope="col">PSC REPORT</th>-->
            <th class="align-middle text-center" style="min-width: 9em" scope="col">NO. DEF</th>
            <th class="align-middle text-center" style="min-width: 9em" scope="col">DETENTION</th>
            <th class="align-middle text-center" style="width: 12em" scope="col">RESPONSIBLE</th>
            <th class="align-middle text-center" style="width: 12em" scope="col">PERSON IN CHARGE</th>
            <th class="align-middle text-center" style="width: 12em" scope="col">PSC BONUS</th>
            <th class="align-middle text-center" style="width: 70px" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="100%">
                <loading></loading>
              </td>
            </tr>
            <tr v-else-if="!reports.length && !loading">
              <td colspan="100%" class="text-center py-3">
                No records found.
              </td>
            </tr>
            <template v-else>
              <tr class="text-uppercase" v-for="(report, index) in reports" :key="report.id">
                <td class="align-middle text-center">{{ index + pagination.from }}</td>
                <td class="align-middle text-center">{{ report.vessel }}</td>
                <td class="align-middle text-center">{{ report.manager }}</td>
                <td>{{ report.title }}</td>
                <td class="align-middle text-white text-center" :class="['bg-'+report.report_status_color]">
                  {{ report.report_status }}
                </td>
                <td class="align-middle text-center">{{ report.port }}</td>
                <td class="align-middle text-center">{{ report.country }}</td>
                <td class="align-middle text-center">{{ report.inspection_date }}</td>
<!--                <td class="align-middle text-center">-->
<!--                  <template v-if="report.pscAttachment">-->
<!--                    <font-awesome-icon icon="file-pdf" v-if="report.pscAttachment.has_file" @click="viewDownloadFile(report.pscAttachment,1,'view',report.id)" size="3x"-->
<!--                                       class="force-danger-all" style="cursor: pointer">-->
<!--                    </font-awesome-icon>-->
<!--                    <font-awesome-icon icon="file-pdf" v-else  size="2x"></font-awesome-icon>-->
<!--                  </template>-->
<!--                </td>-->
                <td class="align-middle text-center"><p class="py-0 my-0" :class="{'h5 text-danger':report.deficiencyCount > 0}">{{ report.deficiencyCount }}</p></td>
                <td class="align-middle text-center"><p class="py-0 my-0" :class="{'h5 text-danger':report.detention}">{{ report.detention ? 'YES' : 'NO' }}</p></td>
                <td class="align-middle text-center">{{ report.responsible_person }}</td>
                <td class="align-middle text-center">{{ report.person_in_charge }}</td>
                <td class="align-middle text-center">{{ report.bounty }}</td>
                <td class="align-middle text-center">
                    <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1"
                            @click="showPscInspection(report.id)"
                            v-if="isAllowed('psc.index.view')">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/elements/AppLoading.vue";
import {mapActions, mapGetters} from "vuex";
import {permissionMixin} from "@/mixins/permissionMixins";
import attachmentMixin from "@/mixins/attachmentMixin";
import axios from "axios";
import {GENERATE_PSC_VESSEL_HISTORY} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {PaginationMixin} from "@/mixins/PaginationMixin";

export default {
  name: "PscInspectionHistory",
  components: {loading},
  mixins:[permissionMixin,attachmentMixin,PaginationMixin],
  data(){
    return {
      loading:false,
      filters: {
        page: 1,
        count: 30,
      }
    }
  },
  computed:{
    ...mapGetters([
      'isLoading', 'reports','pscReport','pagination'
    ])
  },
  methods:{
    ...mapActions(['getPscReports']),
    async setPage(page = null) {
      this.loading=true;
      this.pagination.currentPage = page ?? 1;
      this.filters.page = page ?? 1
      this.filters.vessel_id=this.$route.params.vesselId
      await this.getPscReports(this.filters)
      this.loading=false
    },
    showPscInspection(inspectionId) {
      const router = this.$router.resolve(
        {
          name: 'PscMain',
          params: {
            id: inspectionId
          }
        }
      )
      window.open(router.href, '_blank')

    },
    async generateFile() {
      swal.fire({
        'title': `GENERATING HISTORY FILE <br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      await axios({
        url: GENERATE_PSC_VESSEL_HISTORY+`?vessel_id=${this.$route.params.vesselId}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          let filename = `PSC_REPORT_HISTORY_${this.$route.params.vesselId}_${Date.now()}.pdf`;
          let mime_type = 'application/pdf';
          let url = window.URL.createObjectURL(new Blob([response.data],{type:mime_type}));
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          swal.close()
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          GlobalService.handleError(error, 'DOWNLOAD PSC REPORT TEMPLATE SERVICE')
          return false
        })
    }
  },
  async created() {
    await this.setPage();
  },
  watch:{
    async 'filters.count'(){
      this.loading = true;
      await this.getPscReports(this.filters)
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>


